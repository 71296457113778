<ui-library-dot
  *ngIf="workAvailability() as workAvailability"
  [borderColor]="borderColor()"
  [color]="
    workAvailability === WorkAvailabilityEnum.ACTIVE
      ? 'positive'
      : workAvailability === WorkAvailabilityEnum.PLACED
        ? 'grey'
        : workAvailability === WorkAvailabilityEnum.BLACKLISTED
          ? 'dark'
          : workAvailability === WorkAvailabilityEnum.IN_EMPLOYMENT
            ? 'warning-1'
            : 'grey'
  "></ui-library-dot>
