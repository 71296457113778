import { Component, input } from '@angular/core';
import { UserAvatarModule } from '../../modules/user-avatar/user-avatar.module';
import { ContextMenuModule } from '@intemp/unijob-ui';
import { AsyncPipe, NgIf } from '@angular/common';
import { WorkAvailabilityEnum } from '../../../graphql/generated';
import { DotColor, DotComponent } from '@intemp/unijob-ui2';

@Component({
  standalone: true,
  selector: 'app-talent-workavailability-dot',
  templateUrl: './talent-workAvailability-dot.component.html',
  imports: [UserAvatarModule, ContextMenuModule, AsyncPipe, NgIf, DotComponent],
})
export class TalentWorkAvailabilityDotComponent {
  borderColor = input<DotColor>();
  workAvailability = input.required<WorkAvailabilityEnum>();
  protected readonly WorkAvailabilityEnum = WorkAvailabilityEnum;
}
